import { IScope } from 'angular';
import { IRootScopeService } from 'angular';
import { ILogService } from 'angular';
import { RolePrivilege } from '../../../data/privileges.enum';
import HelperService from '../../../services/helper.service';
import PrivilegeService from '../../../services/privilege.service';
'use strict';

require('./availability.view.component.css');

/* @ngInject */
export default class AvailabilityController {
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $state: any
  public dataService: any;
  public helperService: any;
  public isLoading: boolean = false;
  public mode: AvailabilityTabs = AvailabilityTabs.OVERVIEW;
  public isSynced: boolean = false;
  public settings: any;
  public onlineservice: any;
  public account: any;
  private listeners: any[] = [];

  constructor($rootScope: IRootScopeService, $scope: IScope, $log: ILogService, $state, dataService, helperService: HelperService,
    public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$log.debug('AvailabilityController started...');
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.dataService = dataService;
    this.helperService = helperService;

    //Settings
    this.settings = {
      filteredFunctions: [],
      filteredGroups: [],
      filteredAvailability: []
    };

    if (this.helperService.getFromStorage('availability_settings', false)) {
      this.settings = this.helperService.getFromStorage('availability_settings', this.settings);
    }

    this.init();
    this.initListeners();

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Switch between different display modes
   */
  changeMode(mode: AvailabilityTabs) {
    this.mode = mode;
  }

  initListeners() {
    this.listeners.push(this.$rootScope.$on('tab.change.availability', (event, data) => {
      switch (data) {
        case AvailabilityTabs.ACCESS:
        case AvailabilityTabs.CHART:
        case AvailabilityTabs.FUNCTION:
        case AvailabilityTabs.FUTURE:
        case AvailabilityTabs.OVERVIEW:
        case AvailabilityTabs.RULES:
        case AvailabilityTabs.TABLE:
          this.changeMode(data);
          break;
        default:
          break;
      }
    }));
  }

  /**
   * Load availability
   *
   */
  loadAvailability() {
    this.$log.debug('Refreshing availability');
    //Load
    this.isLoading = true;
    this.dataService.clearFutureCache();
    this.dataService.getAvailability(true, (availability) => {
      this.onlineservice = availability;
      this.isSynced = true;
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      this.$log.error(err);
    });
  };

  init() {

    this.$rootScope.$on('new.account', () => {
      this.selectCurrentTab();
    });

    this.account = this.dataService.getAccount();
    if (this.account) {
      this.selectCurrentTab();
    }
    this.loadAvailability();

    this.$rootScope.$on('os.person.deleted', (event, data) => {
      // We need to update availability
      this.loadAvailability();
    });
  };

  selectCurrentTab() {
    if (this.privilegeService.has(RolePrivilege.Availability_Overview)) {
      this.mode = AvailabilityTabs.OVERVIEW;
    } else if (this.privilegeService.has(RolePrivilege.Availability_Diagrams)) {
      this.mode = AvailabilityTabs.CHART;
    } else if (this.privilegeService.has(RolePrivilege.Availability_GroupsFunctions)) {
      this.mode = AvailabilityTabs.TABLE;
    } else if (this.privilegeService.has(RolePrivilege.Availability_Rules)) {
      this.mode = AvailabilityTabs.RULES;
    } else if (this.privilegeService.has(RolePrivilege.Availability_Access)) {
      this.mode = AvailabilityTabs.ACCESS;
    } else if (this.privilegeService.has(RolePrivilege.Availability_Planning)) {
      this.mode = AvailabilityTabs.FUTURE;
    }
  }
}

export enum AvailabilityTabs {
  OVERVIEW = 'OVERVIEW',
  CHART = 'CHART',
  TABLE = 'TABLE',
  FUNCTION = 'FUNCTION',
  RULES = 'RULES',
  FUTURE = 'FUTURE',
  ACCESS = 'ACCESS'
}
