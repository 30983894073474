'use strict';

require('./category.component.css');

export default class CategoryComponent {
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      category: '='
    }
    this.template = require('./category.component.html');
    this.controller = CategoryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class CategoryComponentController {

  constructor($scope, $rootScope) {
    this.$rootScope = $rootScope;
    this.state = 'LOADING';

    $scope.$watch('ctrl.category', (oldValue, newValue) => {
      if (newValue) {
        this.$rootScope.$on('shiftbook.messages.cnt.' + this.category.categoryId, (event, nbrOfMessages) => {
          this.count = nbrOfMessages;
          if (this.count === 0) {
            this.state = 'SUCCESS';
          } else {
            this.state = 'WARNING';
          }
        });
      }
    });

  }
}
