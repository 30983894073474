import { IScope } from "angular";
import { Emergency, EmergencyReport } from "../../../../../data/emergency.data";
import {RolePrivilege} from "../../../../../data/privileges.enum";
import PrivilegeService from "../../../../../services/privilege.service";

require("./mission.report.component.scss")
/* @ngInject */
export default class MissionReportComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controllerAs: string;
  public bindToController: boolean;
  public controller: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./mission.report.component.html');
    this.scope = {
      emergency: '='
    };
    this.controllerAs = 'ctrl';
    this.bindToController = true;
    this.controller = MissionAlarmComponentController;

  }
}

class MissionAlarmComponentController {
  public emergency: Emergency;
  public report: EmergencyReport;
  public readonly: boolean = true;

  constructor($scope: IScope, public privilegeService: PrivilegeService) {
    this.readonly = !this.privilegeService.has(RolePrivilege.Home_Emergency_Details_Report_Write);

    $scope.$watch('ctrl.emergency', (oldValue, emergency: Emergency) => {
      if (this.emergency && this.emergency.report) {
        this.report = this.emergency.report;
      } else {
        this.emergency.report = {
          wounded: 0,
          losses: 0,
          animalRescues: 0
        } as EmergencyReport;
        this.report = this.emergency.report;
      }

    });
  }
}
