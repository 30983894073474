'use strict';

/* @ngInject */
export default class ChangeStringModalController {
  private $uibModalInstance;
  private str: string;
  private okFunction: Function;

  constructor($uibModalInstance, str: string, okFunction: Function) {
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
    this.str = str;
  }

  cancel() {
    this.$uibModalInstance.close();
  }
  save() {
    this.$uibModalInstance.close('cancel');
    this.okFunction(this.str);
  }
}