import RestService from "../../../../services/rest.service";
import {User, UserAdminContext} from "../../../../data/admin.data";
import angular = require("angular");
import { AdminAddUnitRequest, EUnitType, Unit } from "../../../../data/unit.data";
import { ThirdPartyDraggable } from "@fullcalendar/interaction";
import { EFE2Features, UserAccount } from "../../../../data/account.data";
import AccountService from "../../../../services/account.service";

require('./admin.users.units.component.scss')


export default class AdminUsersUnitsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./admin.users.units.component.html');
    this.scope = {
      user: '=',
    };
    this.controller = UserUnitsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}
/* @ngInject */
class UserUnitsController {
  public isLoading = false;
  private user: User;
  private units: Unit[] = [];
  public hasStatusUnit: boolean = false;
  public hasQueueFeature = false;
  public forcePipelineProtocol: boolean = false;
  public deleteDays: number[] = [0, 1, 7];

  constructor(
    public restService: RestService,
    public $scope: angular.IScope,
    private $log: angular.ILogService,
    public $uibModal,
    private accountService: AccountService,
    private Notification: any,
    public $rootScope: angular.IRootScopeService,
    private dataService: any) {

    this.$scope.$watch('ctrl.user', (oldValue, newValue: User) => {
      if (angular.isDefined(newValue)) {
        this.user = newValue;
        this.load();
      }
    });
  }

  deleteTracing(days: number, unit:Unit){
    this.restService.deleteTracelogs(unit.id, days).then(()=>{
      this.$log.debug("delete trace logs triggered for unit with id ", unit.id);
    }).finally(()=>{
    })

  }


  /**
   * Load all given units
   */
  load() {
    this.hasQueueFeature = this.accountService.isFeatureEnabled(EFE2Features.QUEUES);
    this.isLoading = true;
    this.restService.loadUnitsForUser(this.user.id)
      .then((units) => {
        this.units = units;
        this.hasStatusUnit = this.units.filter(unit => unit.type === EUnitType.STATUS).length > 0
      })
      .catch(err => this.$log.error(err))
      .then(() => this.restService.getForcePipelineProtocolForUser(this.user.id)
        .then(forcePipelineProtocol => this.forcePipelineProtocol = forcePipelineProtocol.value)
      )
      .finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
      })

  }


  /**
   * Delete an existing unit
   * @param unit
   */
  deleteUnit(unit: Unit) {
    this.$uibModal.open({
      template: require('../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.isLoading = true;
            this.restService.deleteUnit(unit.id, () => {
              this.isLoading = false;
              this.load();
            }, (response) => {
              this.isLoading = false;
            });
          };
        },
        additionalText: () => {
          return;
        }
      }
    });
  }

  /**
   * Open the unit settings
   * @param unit
   */
  openUnitSettings(unit: Unit) {

    this.restService.getCustomFeedbacksForUser(this.user.id).then(customFeedbacks => {
      const modalInstance = this.$uibModal.open({
        template: require('../../../modals/pipeline/unit.settings.modal/unit.settings.modal.html'),
        controller: 'UnitSettingsModalController',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          unit: () => {
            return unit;
          },
          user: () => {
            return this.user
          },
          customFeedbackList: () => {
            return customFeedbacks;
          }
        }
      });
      modalInstance.result.then(() => this.load())
    });


  }

  openProtocol(unit: Unit) {
    this.$uibModal.open({
      template: require('../../../modals/pipeline/pipeline.protocol.modal/pipeline.protocol.modal.html'),
      controller: 'PipelineProtocolModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        unit: () => {
          return unit;
        }
      }
    })
  }

  public addDefaultUnit() {
    this.addUnit(EUnitType.DEFAULT);
  }

  public addStatusUnit() {
    this.addUnit(EUnitType.STATUS);
  }

  public addSammelUnit() {
    this.addUnit(EUnitType.SAMMEL);
  }

  public addExpressUnit() {
    this.addUnit(EUnitType.EXPRESS);
  }

  public addWachalarmUnit() {
    this.addUnit(EUnitType.WACHALARM_PRE);
  }

  public addQueueUnit() {
    this.addUnit(EUnitType.QUEUE);
  }

  private addUnit(type: EUnitType): any {
    let customCode = this.useCustomCode(type);

    let modalInstance = this.openModal(type, customCode);
    modalInstance.result.then((request: AdminAddUnitRequest) => {
      this.addUnitForUser(this.user.id, request);
    })
  }

  private addUnitForUser(userId: string, request: AdminAddUnitRequest) {
    this.restService.addUnitForUser(userId, request)
      .then(_ => this.load())
      .catch(err => {
        this.$log.error(err);
        this.Notification.error({
          message: err.data.message
        })
      });
  }

  private useCustomCode(type: EUnitType): boolean {
    switch (type) {
      case EUnitType.DEFAULT:
      case EUnitType.EXPRESS:
        return true;
      default:
        return false;
    }
  }

  private openModal(type: EUnitType, customCode: boolean) {
    return this.$uibModal.open({
      template: require('../../../modals/admin/admin.add.unit.modal/admin.add.unit.modal.html'),
      controller: 'AdminAddUnitModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        type: () => type,
        customCode: () => customCode,
        user: () => this.user
      }
    });
  }


  public toggleForcePipelineProtocol() {
    this.forcePipelineProtocol = !this.forcePipelineProtocol;
    this.restService.setForcePipelineProtocolForUser(this.user.id, {value: this.forcePipelineProtocol})
      .then(() => {
        const account: UserAccount = this.dataService.getAccount();
        if(this.user.id === account.id) {
          account.settings.forcePipelineProtocol = this.forcePipelineProtocol;
        }
      })
  }

}