import { IScope } from 'angular';
import { IRootScopeService } from 'angular';
import RestService from '../../../services/rest.service';
import { UserAccount } from '../../../data/account.data';
import { EVehicleSortOrder, VehicleAssignedAlarm, VehicleRemovedFromAlarm } from '../../../data/alarm.data';
import HelperService from '../../../services/helper.service';
import PrivilegeService from "../../../services/privilege.service";
import {RolePrivilege} from "../../../data/privileges.enum";
'use strict';

require('./assignments.view.component.scss');

/* @ngInject */
export default class AssignmentsController {
  private $uibModal;
  private $rootScope: IRootScopeService;
  private helperService: HelperService;
  private dataService;
  private $state;

  public $scope: IScope;
  public restService: RestService;
  public listeners = [];
  public account: UserAccount;
  public isLoading: boolean = false;
  public hasAlarmListResourcePriv:boolean= false;

  public data: VehicleAssignedAlarm[] = undefined;
  public sortOrder: EVehicleSortOrder = EVehicleSortOrder.TIMESTAMP;
  public reverseOrder = true;

  constructor($scope: IScope, $rootScope: IRootScopeService, $uibModal, $state, dataService, restService: RestService, helperService: HelperService, public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.restService = restService;
    this.dataService = dataService;
    this.helperService = helperService;
    this.$state = $state;
    this.hasAlarmListResourcePriv = privilegeService.has(RolePrivilege.Home_Alarms_Resources);

    this.initListeners();

    // Load sort from storage
    this.sortOrder = this.helperService.getFromStorage('assignmentsSortOrder', 'TIMESTAMP') as EVehicleSortOrder;
    this.reverseOrder = this.helperService.getFromStorage('assignmentsReverseOrder', 'true');


    if (dataService.hasAccount()) {
      this.load();
    }
  }

  /**
   * Init event listeners
   */
  initListeners() {
    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', () => {
      this.load();
    }));
    // New assigned alarm
    this.listeners.push(this.$rootScope.$on('new.assignedAlarm', (event, assignedAlarm: VehicleAssignedAlarm) => {
      if (this.isLoading) return;

      const alreadyAddedVehicleIndex = this.data.findIndex(v => v.vehicleId === assignedAlarm.vehicleId);
      if (alreadyAddedVehicleIndex !== -1) {
        // Remove already added vehicle
        this.data.splice(alreadyAddedVehicleIndex, 1);
      }
      assignedAlarm.isNew = true;
      this.data.splice(0, 0, assignedAlarm);

      this.$scope.$applyAsync();
    }));

    // Remove existing vehicle from alarm
    this.listeners.push(this.$rootScope.$on('deleted.assignedAlarm', (event, removedVehicle: VehicleRemovedFromAlarm) => {
      if (this.isLoading) return;

      const foundVehicle = this.data.findIndex(v => v.vehicleId === removedVehicle.vehicleId);
      if (foundVehicle !== -1) {
        // Remove already added vehicle
        this.data.splice(foundVehicle, 1);
      }
      this.$scope.$applyAsync();
    }));


    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('delete.account', () => {
      this.account = undefined;
    }));

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  /**
   * Change the sort order of the list
   */
  public sortBy(sortOrder: EVehicleSortOrder) {
    this.reverseOrder = !this.reverseOrder;
    this.sortOrder = sortOrder;
    this.helperService.saveInStorage('assignmentsSortOrder', this.sortOrder);
    this.helperService.saveInStorage('assignmentsReverseOrder', this.reverseOrder);
    this.load();
  }

  private load() {
    if (!this.hasAlarmListResourcePriv){
      return;
    }
    this.isLoading = true;
    this.restService.loadAllVehiclesWithAlarm(this.sortOrder, this.reverseOrder).then(result => {
      this.data = result;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }
}
