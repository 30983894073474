'use strict';

require('./number.base.field.css');

export default class NumberBaseFieldComponent {
  constructor() {
    this.restrict = 'E';
    this.template = require('./number.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '='
    };
    this.controller = NumberBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class NumberBaseFieldController {
  constructor() {}
}
