'use strict';
//objectFeedback
export default class ObjectFeedbackComponent {
  constructor() {
    this.restrict = 'A'
    this.template = require('./object.feedback.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectFeedbackComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectFeedbackComponentController {
  constructor() {
    this.states = ['PENDING', 'CREATED', 'ACCEPTED', 'DECLINED', 'RESOLVED'];
  }
}