export interface WebSocketData {
    type: EWebSocketType,
    json: string;
}

export interface WebSocketLogin {
    jwt: string
}

export enum EWebSocketType {
    ALARM = 'ALARM',
    SERVER_STATE = 'SERVER_STATE',
    STATUS = 'STATUS',
    RELAIS = 'RELAIS',
    LOGIN = 'LOGIN',
    INPUT = 'INPUT',
    AMOBILE_CHANGED = 'AMOBILE_CHANGED',
    SERVER_MODE_STATE = 'SERVER_MODE_STATE',
    META_INFO_UPDATED = 'META_INFO_UPDATED',
    VEHICLE_ASSIGNED_TO_ALARM = 'VEHICLE_ASSIGNED_TO_ALARM',
    VEHICLE_REMOVED_FROM_ALARM = 'VEHICLE_REMOVED_FROM_ALARM',
    ICAL_CALENDAR_CHANGED = 'ICAL_CALENDAR_CHANGED',
    QUEUE_ENTRY_ADDED = 'QUEUE_ENTRY_ADDED',
    QUEUE_ENTRY_REMOVED = 'QUEUE_ENTRY_REMOVED',
    QUEUE_ENTRY_UPDATED = 'QUEUE_ENTRY_UPDATED',
    VEHICLE_LOCATION_CHANGED = 'VEHICLE_LOCATION_CHANGED',
    QUEUE_UPDATED = 'QUEUE_UPDATED',
    IOPRINT = 'IOPRINT',
    VEHICLE_ASSIGNED_TO_ALARM_COMBINED = 'VEHICLE_ASSIGNED_TO_ALARM_COMBINED',
    MISSION_REMOVED_FROM_MAP = 'MISSION_REMOVED_FROM_MAP'
}

export interface WebSocketServerStateEvent {
    state: EServerState
}


export interface WebSocketServerModeEvent {
    mode: EServerMode
}

export enum EServerState {
    WORKING = 'WORKING',
    SLEEPING = 'SLEEPING'
}


export enum EServerMode {
    STANDALONE = 'STANDALONE',
    MASTER = 'MASTER',
    SLAVE = 'SLAVE'
}