'use strict';

export default class AvailabilityFutureEntryComponent {
  constructor() {
    this.restrict = 'A',
      this.scope = {
        person: '=',
        date: '=',
        loading: '=',
        threshold: '=',
        hover: '=',
      }
    this.template = require('./future.entry.component.html');

    this.controller = AvailabilityFutureEntryController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

//availabilityFutureEntry
/* @ngInject */
class AvailabilityFutureEntryController {
  constructor($scope, $rootScope, $log, $uibModal, dataService) {

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.dataService = dataService;

    this.isAvailabilityLoading = false;
    this.notYetLoaded = true;
    this.isError = false;
    this.placeholder = [1, 2, 3, 4, 5, 6, 7];
    this.isBelowThreshold = false;

    this.$scope.$watch('ctrl.loading', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }
      if (nVal && this.person !== undefined) {
        this.load();
      }
    })

    this.$scope.$watch('ctrl.date', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }

      if (!this.loading) {
        return;
      }

      if (nVal && this.person !== undefined) {
        if (angular.isDefined(this.loadedTime) && nVal.getTime() !== this.loadedTime.getTime()) {
          this.load();
        } else if (angular.isUndefined(this.loadedTime)) {
          // Never loaded > load again
          this.load();
        }
      }

    });

    this.$rootScope.$on('threshold.update', (event, data) => {
      this.showCaretForDay();
    });
  }

  /**
   * Load month from server
   */
  load() {
    this.isAvailabilityLoading = true;
    this.person._isLoading = true;
    this.dataService.loadWeeklyAvailabilityByDate(this.person, this.date, (response) => {
      this.$log.info(response);
      this.loadedTime = this.date;
      this.notYetLoaded = false;
      this.person._isLoading = false;
      this.person._hasPlanning = true;
      this.isAvailabilityLoading = false;
      this.$rootScope.$emit('future.update', response);
      this.av = response;
      // Trigger event
      this.$rootScope.$emit('future.update.event');
      this.showCaretForDay();
    }, (err) => {
      this.isError = true;
      this.person._isLoading = false;
      this.person._hasPlanning = false;
      this.isAvailabilityLoading = false;
      // Could not load monthly availability
      this.$log.error('Could not load monthly availability');
      this.$log.error(err);
      // Trigger event
      this.$rootScope.$emit('future.update.event');
    });
  }

  showCaretForDay() {
    var continueForloop = true;
    if (angular.isDefined(this.av)) {
      angular.forEach(this.av.entries, (value, key) => {
        if (continueForloop) {
          if (value.availabilityPercentage < this.threshold) {
            this.isBelowThreshold = true;
            continueForloop = false;
            return;
          }
          else {
            this.isBelowThreshold = false;
          }
        }
      });
    }
  }

  /**
   * Hovering over hour
   * @param  hour 
   */
  isHovering(hour, date) {
    this.$rootScope.$emit('future.hovering', { hour: hour.hourStart, date: date.date });
  }

  openPerson(person) {
    this.$uibModal.open({
      template: require('../../../modals/availability/person.availability.modal/person.availability.modal.html'),
      controller: 'PersonAvailabiltyModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        person: () => {
          return person;
        }
      }
    });
  }
}