'use strict'

import { RolePrivilege } from "../../../../data/privileges.enum";
import { Vehicle } from "../../../../data/vehicles.data";
import PrivilegeService from "../../../../services/privilege.service";

require("./vehicle.rescue-services.component.scss")

export default class VehicleRescueServicesComponent {
    public restrict: string;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: string;
    public bindToController: boolean;

    constructor() {
        this.restrict = 'E';
        this.template = require('./vehicle.rescue-services.component.html');
        this.scope = {
            vehicle: '='
        }
        this.controller = VehicleRescueServicesComponentController
        this.controllerAs = 'ctrl';
        this.bindToController = true;
    }
}

class VehicleRescueServicesComponentController {

    public vehicle: Vehicle;
    public hasEditPermission: boolean;

    constructor(private privilegeService: PrivilegeService) {
        this.hasEditPermission = this.privilegeService.has(RolePrivilege.Station_Vehicles_Edit);
    }

}