'use strict';

export default class AvailabilityFunctionSummaryComponent {
  constructor() {
    this.restrict = 'E',
      this.scope = {
        onlineservice: '='
      };
    this.template = require('./functions.summary.component.html');


    this.controller = AvailabilityFunctionSummaryController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AvailabilityFunctionSummaryController {
  constructor($scope, $log) {

    this.$scope = $scope;
    this.$log = $log;

    this.$scope.$watch('ctrl.onlineservice', (nVal) => {
      if (angular.isUndefined(nVal)) {
        return;
      }
    })
  }
}