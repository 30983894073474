'use strict';
//objectEvents
export default class ObjectEventsComponent {
  constructor() {
    this.restrict = 'A'
    this.template = require('./object.events.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectEventsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectEventsComponentController {
  constructor($scope) {
    this.$scope = $scope;
    this.now = new Date();

  }

  /**
   * Returns true, if this event is still valid (now or in the future) and returns false if this event happended in the past
   * @param {*} event 
   */
  isValid(event) {
    if (event.end < this.now) {
      return false;
    }
    return true;
  };

  /**
   * Add a new event
   */
  add () {
    var event = {
      name: '',
      start: (new Date()).getTime(),
      end: (new Date()).getTime(),
      note: ''
    }
    if (angular.isUndefined(this.alarmObject.events)) {
      this.alarmObject.events = [];
    }
    this.alarmObject.events.push(event);
  };

  /**
   * Delete existing event
   * @param {*} event 
   */
  delete(event) {
    this.alarmObject.events.splice(this.alarmObject.events.indexOf(event), 1);
  };
}