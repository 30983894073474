'use strict';

require('./show.layer.css');

/* @ngInject */
export default class ModalShowLayerInstanceController {



  constructor($uibModalInstance, dataService, restService, mapService, $document, leafletData, layer) {
    this.$uibModalInstance = $uibModalInstance;
    this.layer = layer;
    this.leafletData = leafletData;
    this.dataService = dataService;
    this.restService = restService;
    this.mapService = mapService;
    this.$document = $document;

    this.settings = this.dataService.getAccount().settings;

    this.hydrantIconDefault = L.icon({
      iconUrl: 'img/static/Hydrant.svg',
      iconSize: [24, 11], // size of the icon
      iconAnchor: [12, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
    });

    this.hydrantIconDefaultO = L.icon({
      iconUrl: 'img/static/HydrantO.svg',
      iconSize: [24, 20], // size of the icon
      iconAnchor: [12, 10], // point of the icon which will correspond to marker's location
      popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
    });

    this.hydrantIcon24 = L.icon({
      iconUrl: 'img/static/Hydrant24.svg',
      iconSize: [24, 11], // size of the icon
      iconAnchor: [12, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
    });

    this.hydrantIcon48 = L.icon({
      iconUrl: 'img/static/Hydrant48.svg',
      iconSize: [24, 11], // size of the icon
      iconAnchor: [12, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
    });

    this.hydrantIcon96 = L.icon({
      iconUrl: 'img/static/Hydrant96.svg',
      iconSize: [24, 11], // size of the icon
      iconAnchor: [12, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
    });

    this.hydrantIcon24O = L.icon({
      iconUrl: 'img/static/Hydrant24O.svg',
      iconSize: [24, 20], // size of the icon
      iconAnchor: [12, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
    });

    this.hydrantIcon48O = L.icon({
      iconUrl: 'img/static/Hydrant48O.svg',
      iconSize: [24, 20], // size of the icon
      iconAnchor: [12, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
    });

    this.hydrantIcon96O = L.icon({
      iconUrl: 'img/static/Hydrant96O.svg',
      iconSize: [24, 20], // size of the icon
      iconAnchor: [12, 5], // point of the icon which will correspond to marker's location
      popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
    });



    $document.ready(() => {
      L.Icon.Default.imagePath = '/img/static';
      this.map = L.map('layerMapId').setView([this.settings.lat, this.settings.lng], 13);

      this.initLayers();

      if (this.layer.layerType === 'List') {
        this.loadHydrants();
      } else if (this.layer.layerType === 'Lines') {
        this.loadGeoJson();
      }

    });
  }

  loadGeoJson() {
    this.isLoading = true;
    this.restService.loadGeoJsonFromLayer(this.layer, (response) => {
      this.isLoading = false;

      var geoFeature = response.data;

      var myStyle = {
        "color": this.layer.color,
        "weight": 2,
        "opacity": 0.65
      };

      var features = geoFeature.features;
      L.geoJson(features, {
        style: myStyle
      }).addTo(this.map);
    }, (errorResponse) => {
      //Failed
      this.isLoading = false;
    });
  }

  initLayers() {
    this.map.attributionControl.setPrefix('<a style="color:black !important" href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');

    let layers = this.mapService.getBaseLayers();
    
    L.control.layers(layers).addTo(this.map);

    let selectedLayer = this.mapService.getSelectedLayer();
    if(selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.map);

    let mapService = this.mapService
    this.map.on('baselayerchange', function(e) {
      mapService.saveLayer(e.name);
    });

  }

  /**
   * Load hydrants and display on map
   */
  loadHydrants() {
    this.isLoading = true;

    this.restService.loadHydrants(this.layer, (response) => {
      //Success
      this.isLoading = false;
      this.hydrants = response.data;

      var markers = L.markerClusterGroup();




      for (var i = 0; i < this.hydrants.length; i++) {
        var hydrant = this.hydrants[i];

        var markerIcon = this.hydrantIconDefault;


        if (hydrant.hydrantType === 'Oberflurhydrant') {
          var markerIcon = this.hydrantIconDefaultO;

          if (hydrant.flowRateCategory) {
            if (hydrant.flowRateCategory === 'FLOWRATE_24') {
              markerIcon = this.hydrantIcon24O;
            } else if (hydrant.flowRateCategory === 'FLOWRATE_48') {
              markerIcon = this.hydrantIcon48O;
            } else if (hydrant.flowRateCategory === 'FLOWRATE_96') {
              markerIcon = this.hydrantIcon96O;
            }
          }
        } else {
          if (hydrant.flowRateCategory) {
            if (hydrant.flowRateCategory === 'FLOWRATE_24') {
              markerIcon = this.hydrantIcon24;
            } else if (hydrant.flowRateCategory === 'FLOWRATE_48') {
              markerIcon = this.hydrantIcon48;
            } else if (hydrant.flowRateCategory === 'FLOWRATE_96') {
              markerIcon = this.hydrantIcon96;
            }
          }

        }


        var marker = L.marker([hydrant.lat, hydrant.lng], {
          icon: markerIcon
        });



        var popup = hydrant.description;
        popup = popup + '\n' + hydrant.flowRate;

        marker.bindPopup(popup);
        markers.addLayer(marker);
      }

      this.map.addLayer(markers);
    }, (errorResponse) => {
      //Failed
      this.isLoading = false;
    });
  }


  cancel() {
    this.$uibModalInstance.close();
  };
}
