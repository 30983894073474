'use strict';
//objectRadio
export default class ObjectRadioComponent {
  constructor() {
    this.restrict = 'A'
    this.template = require('./object.radio.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectRadioComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectRadioComponentController {
  constructor($scope) {
    this.$scope = $scope;
    this.types = ['TETRA_TMO', 'TETRA_DMO', 'ANALOGE'];

  }

  add() {
    var r = {
      hint: '',
      type: 'TETRA_TMO',
      group: ''
    }
    if (angular.isUndefined(this.alarmObject.radios)) {
      this.alarmObject.radios = [];
    }
    this.alarmObject.radios.push(r);
  };

  delete(r) {
    this.alarmObject.radios.splice(this.alarmObject.radios.indexOf(r), 1);
  };

}
