'use strict';

export default class AlarmExternalCostsComponent {
  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.externalcosts.component.html'),
      this.scope = {
        alarm: '='
      }
  }
  controller() {
  }
}