'use strict';

/* @ngInject */
export default class EditAlarmTemplateGridModalController {
  constructor($scope, $uibModalInstance, $log, $translate, restService, dataService, grid) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.$log = $log;
    this.$translate = $translate;
    this.restService = restService;
    this.dataService = dataService;
    this.grid = grid;
    this.isNewGrid = angular.isUndefined(this.grid.name);

    if (this.isNewGrid) {

      this.grid = {
        emoji: '',
        color: '#737F86',
        name: ''
      }
  
      this.$translate(['EMOJIS.WARNING']).then((translation) => {
        var defaultEmoji = translation['EMOJIS.WARNING'];
        this.grid.emoji = defaultEmoji;
      });
  
    } else {
      this.copyOfModel = angular.copy(this.grid);
    }
  }

  cancel() {
    // Reset data
    if (angular.isDefined(this.copyOfModel)) {
      angular.forEach(this.copyOfModel, (value, key) => {
        this.grid[key] = value;
      });
    }
    this.$uibModalInstance.close();
  };

  ok() {
    this.isLoading = true;
    if (this.isNewGrid) {
      // Is a new template and add it
      this.dataService.addAlarmTemplateGrid(this.grid, () => {
        this.isLoading = false;
        this.$uibModalInstance.close();
      }, (err) => {
        this.isLoading = false;
        this.$log.error(err);
      });
    } else {
      // Update existing template
      this.dataService.updateAlarmTemplateGrid(this.grid, () => {
        this.isLoading = false;
        this.$uibModalInstance.close();
      }, (err) => {
        this.isLoading = false;
        this.$log.error(err);
      });
    }
  }
}