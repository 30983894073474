'use strict';

import { IScope } from "angular";
import RestService from "../../../../../services/rest.service";

require('./admin.logo.component.scss');

export default class AdminLogoSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.logo.component.html');
    this.scope = {
    };
    this.controller = AdminLogoComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminLogoComponentController {
  private isLoading: boolean = false;
  private restService: RestService;
  private $scope: IScope;
  private uploadType: UploadType = UploadType.IMAGE_BACKGROUND;
  public uploader: any;

  public currentFile: any;
  public randomNumber = Math.random();


  constructor($scope: IScope, restService: RestService
  ) {
    this.restService = restService;
    this.$scope = $scope;
    this.uploader = this.restService.createImageUploader();

    this.uploader.onAfterAddingFile = (element) => {
      this.isLoading = true;
      this.currentFile = element;
      this.uploader.uploadAll();
    }

    this.uploader.onBeforeUploadItem = (item) => {
      //Change upload path
      item.url = `${this.restService.getBaseUrl()}/admin/settings/logo?type=${this.uploadType}&fileName=${encodeURIComponent(this.currentFile.file.name)}`;
    };

    this.uploader.onCompleteAll = () => {
      this.isLoading = false;
      this.randomNumber = Math.random();
    };

  }

  selectUploadType(type: UploadType) {
    this.uploadType = type;
  }


  getUrl(img: string): string {
    return `${this.restService.getStaticImageUrl(img)}&random=${this.randomNumber}`;
  }

}

enum UploadType {
  IMAGE_HEADER = 'IMAGE_HEADER',
  IMAGE_LOGO = 'IMAGE_LOGO',
  IMAGE_BACKGROUND = 'IMAGE_BACKGROUND'
}