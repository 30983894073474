import { IScope } from "angular";
import { Server } from "../../../../../data/account.data";
import RestService from "../../../../../services/rest.service";
import { LicenceNumbers } from "../../../../../data/account.data";
import { AdjustLicenceType } from "../../../../../data/admin.data";

require("./admin.licence.settings.scss")

export default class AdminLicenceSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./admin.licence.settings.html');
    this.scope = {
      server: '='
    };
    this.controller = AdminLicenceSettingsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

interface AdditionalLicenceNumbersContainer {
  name: string,
  numbers: LicenceNumbers
}

class AdminLicenceSettingsComponentController {

  public isRefreshingLicence: boolean = false;
  public server: Server;
  public additionalLicencesSorted: AdditionalLicenceNumbersContainer[] = [];

  constructor(private readonly restService: RestService,
    private readonly $scope: IScope) {
      this.$scope.$watch('ctrl.server', _ => {
        this.additionalLicencesSorted = this.getAdditionalLicences();
        this.$scope.$applyAsync();
      })
      
    }

  /**
   * Refresh the FE2 licence
   */
  refreshLicence() {
    this.isRefreshingLicence = true;
    this.restService.refreshLicence().then((serverResponse: Server) => {
      this.server = serverResponse;
    }).finally(() => {
      this.isRefreshingLicence = false;
      this.$scope.$applyAsync();
    });
  }

  getAdditionalLicences() {
    const licences = this.server.licences;
    const list: AdditionalLicenceNumbersContainer[] = Object.keys(licences).map(key => {
      const value = licences[key] as LicenceNumbers
      return {
        name: key, 
        numbers: value
      }
    });
    list.sort(this.sortLicencesAlphabetically);
    list.sort(this.sortLicencesByType)
    return list;
    
  }

  sortLicencesAlphabetically(a: AdditionalLicenceNumbersContainer, b: AdditionalLicenceNumbersContainer): number {
    return a.name.localeCompare(b.name);
  }

  sortLicencesByType(a: AdditionalLicenceNumbersContainer, b: AdditionalLicenceNumbersContainer): number {
    return a.numbers.type.localeCompare(b.numbers.type);
  }

  getAdjustLicenceType(additionalLicenceName: string) {
    return AdjustLicenceType[additionalLicenceName];
  }

}