'use strict';

import { TransitionHookScope } from "@uirouter/core";
import { ILogService, IScope } from "angular";
import { VehicleSwapHistory } from "../../../../data/admin.data";
import { Vehicle } from "../../../../data/vehicles.data";
import RestService from "../../../../services/rest.service";

require('./swap.history.css');

/* @ngInject */
export default class VehicleSwapHistoryModalController {
  public $scope: IScope;
  public $uibModal;
  public $log: ILogService;
  public $uibModalInstance: any;
  public restService: RestService;
  public isLoading: boolean = false;
  public limit: number = 25;
  public data: VehicleSwapHistory = {
    currentPage: 1
  } as VehicleSwapHistory;


  constructor($scope: IScope, $log: ILogService, $uibModal, $uibModalInstance, restService: RestService) {
    this.$scope = $scope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;

    this.pageChanged();
  }

  pageChanged() {
    this.isLoading = true;
    this.restService.getSwapProtocol(this.data.currentPage - 1, this.limit).then(response => {
      this.data = response;
      this.data.currentPage = this.data.currentPage + 1;
    }).catch(error => {
      this.$log.error(error);
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  };

  edit(vehicleId: string) {
    this.$uibModal.open({
      template: require('../vehicle.modal/vehicle.modal.html'),
      controller: 'VehicleModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        vehicle: () => {
          return undefined;
        },
        vehicleId: () => {
          return vehicleId;
        }
      }
    });
  }


  cancel() {
    this.$uibModalInstance.close();
  }
}
