'use strict';

//alarmVideo
export default class AlarmVideoComponent {
  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.video.component.html'),
      this.scope = {
        alarm: '='
      },
      this.controller = AlarmVideoComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmVideoComponentController {
  constructor(restService, $http) {
    this.$http = $http;
    this.restService = restService;
  }

  getVideoImage() {
    return this.restService.getBaseUrl() + '/files/cameras/' + encodeURIComponent(this.alarm.parameters.snapFileName) + '?Authorization=' + this.$http.defaults.headers.common.Authorization;
  };

}
