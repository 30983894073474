'use strict';

require('./delete.revoked.tokens.modal.css');

/* @ngInject */
export default class DeleteRevokedTokensModalController {
  constructor($scope, $uibModalInstance, okFunction) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;

  }

  ok() {
    this.$uibModalInstance.close();
    this.okFunction(this.name, this.color);
  };

  cancel() {
    this.$uibModalInstance.close();
  };
}
