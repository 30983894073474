'use strict';
//objectDangerSubstances
export default class ObjectDangerSubstancesComponent {
  constructor() {
    this.restrict = 'A'
    this.template = require('./object.danger.substances.component.html');
    this.scope = {
      alarmObject: '=',
      isEditable: '='
    };
    this.controller = ObjectDangerSubstancesComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ObjectDangerSubstancesComponentController {
  constructor() {
  }

  /**
   * Add a new substance
   */
  addSubstance() {
    var data = {
      location: '',
      type: '',
      risk: '',
      un: '',
      riskClass: 'DANGER',
      actions: '',
        quantity: '',
        protection: ''
      };
    if (angular.isUndefined(this.alarmObject.risks.substances)) {
      this.alarmObject.risks.substances = [];
    }
    this.alarmObject.risks.substances.push(data);
  };

}
