'use strict';

import { IScope } from "angular";
import { AlarmMonitor, AlarmMonitorAddressbookResponse, AlarmMonitorType } from "../../../../data/am4.data";
import { BaseField } from "../../../../data/basefield.data";
import RestService from "../../../../services/rest.service";
import { SortParams } from "../../../views/addressbook.view.component/addressbook.view.component";

require('./am4.base.field.css');

export default class AM4BaseFieldComponent {
  public restrict: any;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: any;
  public bindToController: any;

  constructor() {
    this.restrict = 'E';
    this.template = require('./am4.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '='
    };
    this.controller = AM4BaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class AM4BaseFieldController {
  public field: BaseField;
  public restService: RestService;
  public $scope: IScope;
  public alarmMonitors: AlarmMonitorAddressbookResponse;
  public isLoading = false;
  private params = {
    currentPage: 0,
    totalElements: 0,
    pageSize: 20
  } as SortParams;

  constructor(restService: RestService, $scope: IScope) {
    this.restService = restService;
    this.$scope = $scope;

    $scope.$watch('ctrl.field', (newValue) => {
      if (newValue) {
        this.pageChanged();
      }
    });
  }

  /**
   * Returns true, if the AM4 is selected
   * @param {am4} am
   */
  isSelected(alarmMonitor: AlarmMonitor) {
    for (var i = 0; i < this.field.value.length; i++) {
      if (this.field.value[i] === alarmMonitor.id) {
        return true;
      }
    }
    return false;
  }

  /**
   * Select the given AM4
   * @param {AM4} am
   */
  select(alarmMonitor: AlarmMonitor) {
    if (this.isSelected(alarmMonitor)) {
      //Remove
      var indexToDelete = -1;
      for (var i = 0; i < this.field.value.length; i++) {
        if (this.field.value[i] === alarmMonitor.id) {
          indexToDelete = i;
          break;
        }
      }
      if (indexToDelete > -1) {
        this.field.value.splice(indexToDelete, 1);
      }
    } else {
      //Add
      this.field.value.push(alarmMonitor.id);
    }
  }


  pageChanged() {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.restService.loadAlarmMonitorsForBaseField(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.pageSize, this.field.filter as AlarmMonitorType)
        .then((result: AlarmMonitorAddressbookResponse) => {
          this.alarmMonitors = result;
          this.params.totalElements = this.alarmMonitors.totalElements;
          this.params.totalPages = this.alarmMonitors.totalPages;
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });
  }
}
