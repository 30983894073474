'use strict';

import { Relais } from "../../../../data/admin.data";
import { BaseField } from "../../../../data/basefield.data";


require('./relais.base.field.css');

export default class RelaisBaseFieldComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./relais.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '='
    };
    this.controller = RelaisTextBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}




/* @ngInject */
class RelaisTextBaseFieldController {
  field: BaseField;
  relais: Relais[] = [];

  constructor(dataService: any) {
    dataService.loadRelais((result => {
      this.relais = result;
    }), (err) => {
      console.error(err);
    });
  }


  isSelected(relay: Relais) {
    for (var i = 0; i < this.field.value.length; i++) {
      if (this.field.value[i] === relay.id) {
        return true;
      }
    }
    return false;
  };


  select(relay: Relais) {
    if (this.isSelected(relay)) {
      //Remove
      var indexToDelete = -1;
      for (var i = 0; i < this.field.value.length; i++) {
        if (this.field.value[i] === relay.id) {
          indexToDelete = i;
          break;
        }
      }
      if (indexToDelete > -1) {
        this.field.value.splice(indexToDelete, 1);
      }
    } else {
      //Add
      this.field.value.push(relay.id);
    }
  }
}