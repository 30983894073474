'use strict';

import { ProtocolEntry } from "../../../../data/admin.data";
import { AlarmData, EAlarmProtocolType, Protocol } from "../../../../data/alarm.data";
import { EFeedbackState } from "../../../../data/customFeedback.data";
import { AssetStrength, EVehicleStatus } from "../../../../data/vehicles.data";


require('./alarm.protocol.component.css');


//alarmProtocol
export default class AlarmProtocolComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E'
    this.template = require('./alarm.protocol.component.html');
    this.scope = {
      alarm: '='
    };
    this.controller = AlarmProtocolComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AlarmProtocolComponentController {
  public helperService: any;
  public alarm: AlarmData;

  constructor(helperService) {
    this.helperService = helperService;
  }

  calcDiff(entry: Protocol, index: number) {
    var arr = this.alarm.protocol;
    var length = arr.length;
    var firstElement = arr[length - 1];
    if (length <= 1) {
      // TOO less elements
      return 0;
    }
    if (index === 0) {
      // Last element
      return entry.timestamp - firstElement.timestamp;
    }
    if (index === length - 1) {
      // First element
      return 0;
    }
    return entry.timestamp - firstElement.timestamp;

  };

  filterForResponse(entries: Protocol[], onlyFeedbackResponses: boolean) {
    return entries.filter((entry) => onlyFeedbackResponses ? entry.type === EAlarmProtocolType.RESPONSE : entry.type !== EAlarmProtocolType.RESPONSE);
  }

  hasResponseProtocol(entries: Protocol[]) {
    return entries.filter((entry) => entry.type === EAlarmProtocolType.RESPONSE).length > 0;
  }

  getBackgroundColorForFeedback(feedback) {
    return this.helperService.getBackgroundColorForFeedback(feedback);
  }

  getIconClassForFeedback(feedback: EFeedbackState) {
    return this.helperService.getIconClassForFeedback(feedback);
  }

  hasStrength(entry: Protocol): boolean {
    if(entry.type !== EAlarmProtocolType.STATUS) {
        return false;
    }
    if(!entry.strength) {
      return false;
    }
    let status = entry.status;
    if(status == EVehicleStatus.STATUS_1 
      || status == EVehicleStatus.STATUS_3 
      || status == EVehicleStatus.STATUS_4 
      || status == EVehicleStatus.STATUS_7 
      || status == EVehicleStatus.STATUS_8) {
      return true;
    }
  }

  totalStrength(strength: AssetStrength): number {
    return strength.cntBattalionLeader + strength.cntUnitLeader + strength.cntCrew;
  }
}