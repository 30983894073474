import { EAdditionalLicence } from "./amobile.data";

export interface ValidationResponse {
    severity: ValidationSeverity;
    validationResult: EValidationResult;
    errorMessage: string;
}

export enum EValidationResult {
    VALID = 'VALID', INVALID = 'INVALID'
}

export interface BaseField {
    key: string;
    label: string;
    category?: string;
    version: number;
    filter?: string;
    options?: any;
    value: any;
    type: BaseFieldType;
    optional: boolean;
    validationResponse: ValidationResponse;
    validatorType: EValidatorType;
    requiredFeature: EAdditionalLicence;
}

export enum ValidationSeverity {
    OK = 'OK', WARN = 'WARN', ERROR = 'ERROR'
}

export enum EValidatorType {
    DUMMY = 'DUMMY', TEXT_NOT_EMPTY = 'TEXT_NOT_EMPTY', LIST_NOT_EMPTY = 'LIST_NOT_EMPTY',
    QUICK_EDIT_AWARE_LIST_NOT_EMPTY = 'QUICK_EDIT_AWARE_LIST_NOT_EMPTY', NUMBER_BETWEEN_1_53 = 'NUMBER_BETWEEN_1_53',
    NUMBER_NOT_0 = 'NUMBER_NOT_0', STRING_IS_TIME = 'STRING_IS_TIME', NUMBER_NOT_EMPTY = 'NUMBER_NOT_EMPTY'
}

export enum BaseFieldType {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    TEXT_MULTI = 'TEXT_MULTI',
    CHECKBOX = 'CHECKBOX',
    PASSWORD = 'PASSWORD',
    LABEL = 'LABEL',
    HEADLINE = 'HEADLINE',
    SEPERATOR = 'SEPERATOR',
    DROPDOWN = 'DROPDOWN',
    PERSON_SELECT = 'PERSON_SELECT',
    UNIT_SELECT = 'UNIT_SELECT',
    TABLE = 'TABLE',
    AM3_SELECT = 'AM3_SELECT',
    AML_SELECT = 'AML_SELECT',
    AM4CLOUD_SELECT = 'AM4CLOUD_SELECT',
    VEHICLE_SELECT = 'VEHICLE_SELECT',
    OS_API = 'OS_API',
    COLOR = 'COLOR',
    UUID = 'UUID',
    DATABASE_PERSON_SELECT = 'DATABASE_PERSON_SELECT',
    USER_SELECT = 'USER_SELECT',
    RELAIS = 'RELAIS',
    CODE_MAP= 'CODE_MAP',
    MAIL_PROVIDER_SELECT='MAIL_PROVIDER_SELECT'

}