import { ILogService, IScope } from "angular";
import { Announcement, AnnouncementResponsePaginated, AnnouncementResponses } from "../../../../data/announcement.data";
import {RolePrivilege} from "../../../../data/privileges.enum";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./announcement.component.css');

export default class AnnouncementComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./announcement.component.html');
    this.scope = {
    };
    this.controller = AnnouncementComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AnnouncementComponentController {
  public restService: RestService;
  public $log: ILogService;
  public $scope: IScope;
  public isLoading: boolean;

  public limit: number = 25;
  public data: AnnouncementResponsePaginated;
  public globalAnnouncements: Announcement[] = [];

  constructor($log: ILogService, $scope: IScope, private $uibModal, restService: RestService, public privilegeService: PrivilegeService) {
        this.restService = restService;
        this.$log = $log;
        this.$scope = $scope;

    this.loadAnnouncements(0);
    }

    editAnnouncement(announcement: Announcement) {
      if (this.privilegeService.has(RolePrivilege.Announcements_Edit)) {
        var modalInstance = this.$uibModal.open({
          template: require('../../../modals/wache/edit.announcement.modal/edit.announcement.modal.html'),
          controller: 'EditAnnouncementModalController',
          controllerAs: 'ctrl',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            announcement: () => {
              return announcement;
            }
          }
        });
        modalInstance.result.then(() => this.loadAnnouncements(this.data.number - 1));
      }
    }

    pageChanged() {
      this.loadAnnouncements(this.data.number - 1);
    }

    showPageControl() {
      return this.data && this.data.totalPages > 1;
    }

    loadAnnouncements(page: number) {
      this.isLoading = true;

      this.restService.loadAnnouncements(page, this.limit).then((response: AnnouncementResponses) => {
        this.data = response.announcements;
        this.globalAnnouncements = response.globalAnnouncements;
        this.data.number = this.data.number + 1;
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$apply();
      });
    };

    addAnnouncement() {
      this.$uibModal.open({
        template: require('../../../modals/objects/add.object.modal/add.object.modal.html'),
        controller: 'AddAlarmObjectCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        size: 'sm',
        resolve: {
          okFunction: () => {
            return (name) => {
              this.isLoading = true;
              this.restService.newAnnouncement(name).then((announcement) => {
                this.isLoading = false;
                // Open new created
                if (!this.privilegeService.has(RolePrivilege.Announcements_Edit)) {
                  this.loadAnnouncements(this.data.number - 1);
                  return;
                }
                this.editAnnouncement(announcement);
              }, (response) => {
                //Error occured
                this.$log.error(response);
                this.isLoading = false;
              });
            }
          }
        }
      });
    }
}