'use strict';

/* @ngInject */
export default class ConfirmDeleteModalController {
  constructor($uibModalInstance, okFunction, additionalText) {

    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
    this.additionalText = additionalText;
  }


  cancel() {
    this.$uibModalInstance.close();
  };

  ok() {
    this.$uibModalInstance.close();
    this.okFunction();
  };

}
