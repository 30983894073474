'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import RestService from "../../../../services/rest.service";

require('./admin.logs.component.css');

export default class AdminLogsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./admin.logs.component.html');
    this.scope = {
    };
    this.controller = AdminLogsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminLogsComponentController {
  public $scope: any;
  public $rootScope: any;
  public dataService: any;
  public isLoading: boolean = false;
  public $log: ILogService;
  public restService: RestService;
  public logFiles: any;

  constructor($scope: IScope, $rootScope: IRootScopeService, dataService, $log: ILogService, restService: RestService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.dataService = dataService;
    this.$log = $log;
    this.restService = restService;
    this.loadLogFiles();
  };

  loadLogFiles() {
    this.isLoading = true;
    this.dataService.getLogs(
      (logs) => {
        this.$log.debug(logs);
        this.logFiles = logs;
        this.isLoading = false;
      },
      (response) => {
        //Error occured
        this.isLoading = false;
        this.$log.error(response);
      });
  };


  getLogPath(log) {
    return '/rest/logs/' + log.fileName + '?Authorization=' + this.restService.getAuthHeader();
  };
}



