'use strict';

export default class AvailabilityFutureFunctionsComponent {
  constructor() {
    this.restrict = 'E',
      this.scope = {
        date: '=',
        functions: '='
      }
    this.template = require('./future.functions.component.html');

    this.controller = AvailabilityFutureFunctionsController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class AvailabilityFutureFunctionsController {
  constructor($scope, $rootScope, $log, $uibModal, dataService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.isExpaneded = false;
  }

  setExpanded() {
    this.isExpaneded = !this.isExpaneded;
  }
}