'use strict';

import { IRootScopeService, IScope } from "angular";
import { PrivacySettingsResponse } from "../../../../../data/admin.settings";
import RestService from "../../../../../services/rest.service";

require('./admin.privacy.component.css');

export default class AdminPrivacySettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA';
    this.template = require('./admin.privacy.component.html');
    this.scope = {
    };
    this.controller = AdminPrivacyComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminPrivacyComponentController {
  private isLoading: boolean = false;
  private restService: RestService;
  private settings: PrivacySettingsResponse;
  private $scope: IScope;


  constructor($scope: IScope, $rootScope: IRootScopeService, restService: RestService) {
    this.restService = restService;
    this.$scope = $scope;
    this.load();
  }


  /**
   * Load privacy settings
   */
  load() {
    this.isLoading = true;
    this.restService.getAdminPrivacySettings().then(response => {
      this.settings = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Save privacy settings
   */
  save() {
    this.isLoading = true;
    this.restService.saveAdminPrivacySettings(this.settings).then(response => {
      this.settings = response;
    }).finally(() => {
      this.isLoading = false;
      this.$scope.$applyAsync();
    });
  }


}

