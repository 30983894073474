import { EAvailabilityState } from "./availability.data";
import { RoleSimpleResponse } from "./role.data";

export interface ApagerPersonData {
  status: string;
  deviceType: string;
  lastStatusCheck: number;
  provisioningId: string;
  version: number;
  hasPublicKey: boolean;
  publicKeyUpdated: number;
  installedVersion: number;
  role: ProvisioningType;
  lastConfirmationTs: number;
}

export interface PersonProvisioningProfile {
  id: string;
  name: string;
  version: number;
}


export interface PersonData {
  aPagerPro: string;
  email: string;
  ISSI: string;
  mobil: string;
}

export interface Person {
  firstName: string;
  lastName: string;
  note: string;
  displayName: string;
  personID: string;
  apagerFieldMode:EAPagerFieldMode;
  data: PersonData;
  authentificationID: string;
  parent: string;
  apagerPersonData: ApagerPersonData;
  usersWithAccess: string[];
  sharedSecret: string;
  osId: number;
  functions: string[];
  groups: string[];
  state: string;
  connectStatusWithAvailability: boolean;
  shared: boolean;
  hasOS: boolean;
  isMigratedToToken: boolean;
  keyOfProvisioningField:ProvisionField;

  hasProvisioningProfile: boolean;
  provisioningProfile: PersonProvisioningProfile;

  availability: EAvailabilityState;
  color: string;
  timeOfUpdate: number;
  excludedFunctions: string[];
  excludedGroups: string[];

  notifications: Notifications;
  username: string;
  tmpPassword: string;
  dataBasePersonType: EDataBasePersonType;
  loginAllowed: boolean;
  sendPasswordViaMail: boolean;
  sendPasswordViaApager: boolean;

  pendingAlarmGroups: PendingAlarmGroup[];

  role: RoleSimpleResponse;

  substitutes: string[];
  substituteResponse: PersonSubstituteResponse;
  personnelId: string;
}
export interface APagerSettings{
    apagerFieldMode: EAPagerFieldMode;
    keyOfProvisioningField: ProvisionField;
  apagerProValue: string;
  tokenGenerated: boolean;
}
export interface ContactData{
  email: string;
  issi: string;
  mobil: string;
}
export interface SinglePersonResponse{
  person:ExternalDatabasePersonResponse;
  lstOfFields:string[];
}


export interface ExternalDatabasePersonResponse{
  firstName: string;
  lastName: string;
  note: string;
  displayName: string;
  personID: string;
  aPagerExists:boolean;
  apagerSettings: APagerSettings;
  data: ContactData;
}

export interface PersonNewFieldStructure {
  firstName: string;
  lastName: string;
  note: string;
  displayName: string;
  personID: string;
  apagerSettings: APagerSettings;
  data: ContactData;
  authentificationID: string;
  parent: string;
  apagerPersonData: ApagerPersonData;
  usersWithAccess: string[];
  sharedSecret: string;
  osId: number;
  functions: string[];
  groups: string[];
  state: string;
  connectStatusWithAvailability: boolean;
  shared: boolean;
  hasOS: boolean;
  isMigratedToToken: boolean;

  hasProvisioningProfile: boolean;
  provisioningProfile: PersonProvisioningProfile;

  availability: EAvailabilityState;
  color: string;
  timeOfUpdate: number;
  excludedFunctions: string[];
  excludedGroups: string[];

  notifications: Notifications;
  username: string;
  tmpPassword: string;
  dataBasePersonType: EDataBasePersonType;
  loginAllowed: boolean;
  sendPasswordViaMail: boolean;
  sendPasswordViaApager: boolean;

  pendingAlarmGroups: PendingAlarmGroup[];

  roles: RoleSimpleResponse[];

  substitutes: string[];
  substituteResponse: PersonSubstituteResponse;
  allowedIPRange: string;

  hasExceptionForAlarmUpdatesViaApagerPro: boolean;
  adminDisableDistributionOfAlarmupdatesViaApager: boolean
}
export enum ProvisionField{
  EMAIL='EMAIL',
  MOBIL='MOBIL'
}

export interface PersonSubstituteResponse {
  created: number;
  lastChanged: number;
  substitutes: PersonSearchQueryResult[]
}

export interface PersonData {
  aPagerPro: string;
  email: string;
  ISSI: string;
  mobil: string;
}

export interface PersonSimple {
  note: string;
  displayName: string;
  apagerPro: string;
  personID: string;
  apagerPersonData: ApagerPersonData;
  hasProvisioningProfile: boolean;
  provisioningProfileId: string;
  functions: string[];
  groups: string[];
  state: string;
  shared: boolean;
  hasOS: boolean;
  availability: EAvailabilityState;
  color: string;
  excludedFunctions: string[];
  excludedGroups: string[];
  username: string;
  dataBasePersonType: EDataBasePersonType;
  pendingAlarmGroups: PendingAlarmGroup[];
  personnelId: string;
}

export interface PendingAlarmGroup {
  name: string;
  id: string;
}

export interface PersonSearchQueryResult {
  displayName: string;
  personID: string;
}

export interface Notifications {
  viaEmail: boolean;
  viaAPager: boolean;
  onNewPersonRegistered: boolean;
  onNewRoadblock: boolean;
  onNewVehicleFault: boolean;
  onObjectReleased: boolean;
  onNewObjectFeedback: boolean;
  onObjectExaminated: boolean;
  onServerStart: boolean;
  onMasterSlaveChange: boolean;
  onLicenceExpired: boolean;
  onInputStateChanged: boolean;

}

export interface PersonsAddressbookResponse {
  currentPage: number,
  length: number,
  nbrOfOwnPersons: number,
  pendingPersons: PersonSimple[],
  persons: PersonSimple[],
  totalElements: number,
  totalPages: number,
  licenced: number,
  inUse: number,
  licencedPercentage: number,
  remaining: number,
  lstOfFields: string[]
}

export interface PersonsAddressbookFullResponse {
  currentPage: number,
  length: number,
  nbrOfOwnPersons: number,
  pendingPersons: Person[],
  persons: Person[],
  totalElements: number,
  totalPages: number,
  licenced: number,
  inUse: number,
  licencedPercentage: number,
  remaining: number,
  lstOfFields: string[]
}

export interface OrganisationPaginatedResponse {
  currentPage: number;
  totalElements: number;
  totalPages: number;
  data: UserSearchResponse[];
  length: number;
}

export interface SharePersonRequest {
  personId: string;
  userIds: string[];
}

export interface UserSearchResponse {
  userName: string;
  userId: string;
}


export enum EDataBasePersonType {
  DEFAULT = 'DEFAULT',
  SHARED = 'SHARED'
}

export interface ChangeFunctionGroupForPerson {
  personID: string;
  name: string;
  action: ActionType;
  type: Type;
}

export enum ActionType {
  ADD = 'ADD',
  REMOVE = 'REMOVE'
}

export enum Type {
  FUNCTIONS = 'FUNCTIONS',
  GROUPS = 'GROUPS'
}

export interface APagerTokenForPersonResponse{
  token:string;
  personID:string;
}
export enum EAPagerFieldMode{
  LEGACY="LEGACY",TOKEN="TOKEN", NO_APAGER="NO_APAGER"
}

export interface ProvisioningVersionResponse {
  provisioningDbId: string;
  version: number;
  role: ProvisioningType;
  lastConfirmationTs: number;
  apagerPro: string;
}
export enum ProvisioningType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}


export interface DeletePersonRequest {
  personId: string;
  keepOs: boolean;
  fullWipe: boolean;
  wipeReason: string;
}

export interface PaginatedDatabasePersonProvisioningRepresentation{
  totalElements:number;
  totalPages: number
  content: DatabasePersonProvisioningRepresentation[];
}

export interface PaginatedDatabasePersonForAlarmGroups {
  totalElements:number;
  totalPages: number
  content: DataBasePersonForAlarmGroups[];
}

export interface PaginatedDatabasePersonForTracking {
  totalElements:number;
  totalPages: number
  content: DataBasePersonForTracking[];
}

export interface DataBasePersonForTracking{
  id: string;
  displayName:string;
  note: string;
  osId: number;
  availability: AvailabilityRepresentationForTracking;
  connectStatusWithAvailability: boolean;
  apagerPersonData: APagerDeviceTypeRep;

}
export interface AvailabilityRepresentationForTracking{
  availability:EAvailabilityState;
  color:string;
}

export interface APagerDeviceTypeRep{
  deviceType: string;
}

export interface DataBasePersonForAlarmGroups {
  id: string;
  displayName:string;
  note: string;
}

export interface DatabasePersonProvisioningRepresentation{
  id: string;
  apager: string;
  displayName:string;
  note: string;
  smsCosts: boolean;
  apagerPersonData: ApagerPersonDataRep;
  originParentName: string;
}

export interface ApagerPersonDataRep{
  status: string;
  deviceType: string;
  lastStatusCheck: number;
  provisioningId: string;
  provisioningName: string;
  version: number;
  publicKey: boolean;
  publicKeyUpdated: number;
  reportedVersion: ReportedVersionRep;
}
export interface PersonQuickEditResponse{
  persons:PersonQuickEdit[];
  totalElements:number;
  totalPages:number;
  currentPage:number;
}
export interface PersonQuickEdit{
  personID:string;
  displayName:string;
  note:string;
  availability:EAvailabilityState;
}

export interface ReportedVersionRep{
  version: number;
  lastConfirmationTs: number;
  apagerPro?: string;
  role: ProvisioningType;
}