'use strict';

import { DeletePersonRequest } from "../../../../data/person.data";

//Controller for isDirty check

//ConfirmPersonDeleteCtrl
/* @ngInject */
export default class ConfirmPersonDeleteModalController {
    public $scope: any;
    public $uibModalInstance: any;
    public dataService: any;
    public okFunction: any;
    public hasOnlineService: boolean;
    public deleteOsPerson: boolean = true;
    public fullWipe: boolean = false;
    public fullWipeReason: string;
    // use this to hide the options to delete online service of old share person (old remove access didn't do that as well), full wipe doesn't make sense ether
    public isOldShare: boolean= false;

    constructor($scope, $uibModalInstance, dataService, okFunction: Function, isOldShare: boolean) {
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.dataService = dataService;
        this.okFunction = okFunction;
        this.hasOnlineService = this.dataService.getAccount().hasOnlineService;
        this.isOldShare = isOldShare;
    }

    cancel() {
        this.$uibModalInstance.close();
    }

    ok() {
        this.$uibModalInstance.close();
        this.okFunction({
            fullWipe: this.fullWipe,
            keepOs: !this.deleteOsPerson,
            wipeReason: this.fullWipeReason
        } as DeletePersonRequest);
    }
}