'use strict';

import { IRootScopeService, IScope, ILogService, ILocationService } from "angular";
import { ELoginType, UserAccount } from "../../../../data/account.data";
import { EVehicleStatus, StatusEntryResponse, StatusGrid, Vehicle, VehicleStatusHistoryResponse } from "../../../../data/vehicles.data";
import RestService from "../../../../services/rest.service";
import VehicleService from "../../../../services/vehicle.service";
import { WacheTabs } from "../../../views/wache.view.component/wache.view.component";


require('./tableau.component.css');

export default class StatusTableauComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public require: any;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./tableau.component.html');
    this.scope = {
    };
    this.controller = StatusTableauComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}




/* @ngInject */
class StatusTableauComponentController {
  public vehicleService: VehicleService;
  public $log: ILogService;
  public $location: ILocationService;
  public dataService;
  public $scope: IScope;
  public $uibModal;
  public listeners = [];

  public activeGroup: string;
  public groups: string[] = [];
  public grid: StatusGrid;
  public lstOfAllDisplayedVehicleIds: string[] = [];
  public isLoadingGroups: boolean = true;
  public isLoadingGrid: boolean = false;
  public isTabActive: boolean = false;
  public isSoundActive: boolean = false;
  public isFirstVisit: boolean = true;
  public isPersonLoggedIn: boolean = false;
  public showGrid: boolean = false;
  // All vehicles with STATUS_5 or STATUS_0
  public vehiclesWithSpeakingRequest: Vehicle[] = [];

  public soundStatus5;
  public soundStatus0;


  constructor($log: ILogService, $scope: IScope, $rootScope: IRootScopeService, $location: ILocationService, $uibModal, vehicleService: VehicleService, restService: RestService, dataService) {
    this.$uibModal = $uibModal;
    this.$location = $location;
    this.dataService = dataService;
    this.$log = $log;
    this.$scope = $scope;
    this.vehicleService = vehicleService;
    
    this.listeners.push($rootScope.$on('update.selectedTabWache', (event, selectedTab: WacheTabs) => {
      switch (selectedTab) {
        case WacheTabs.TABLEAU:
          if (this.isFirstVisit) {
            // Activate sounds only on first visit
            this.isSoundActive = true;
          }
          this.isFirstVisit = false;
          this.isTabActive = true;
          this.isPersonLoggedIn = (this.dataService.getAccount() as UserAccount).loginType === ELoginType.PERSON;
          this.loadGroups(false);
          break;
        default:
          this.isTabActive = false;
          break;
      }
    }));

    this.listeners.push($rootScope.$on('refresh.statusTableau', () => {
      // Reload groups
      this.loadGroups(true).then(() => this.loadGrid(this.activeGroup));
    }));

    this.listeners.push($rootScope.$on('sound.statusTableau', (event, isActive) => {
      this.isSoundActive = isActive;
      this.$log.info('Play status sounds: ' + this.isSoundActive);
    }));

    // Register for sounds
    this.listeners.push($rootScope.$on('status.change', (event, incomingStatus: StatusEntryResponse) => {
      this.incomingStatus(incomingStatus);
    }));
    this.soundStatus5 = new Audio(restService.getBaseUrl() + '/files/sounds?name=status_5.mp3');
    this.soundStatus0 = new Audio(restService.getBaseUrl() + '/files/sounds?name=status_0.mp3');

    $scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });


  }

  /**
   * Load all groups
   */
  loadGroups(forceUpdate: boolean) {
    return new Promise<boolean>((resolve, reject) => {
      this.isLoadingGroups = true;

      this.vehicleService.getGroupsForTableau(forceUpdate).then(groups => {
        this.groups = groups;
        if (this.groups.length > 0) {
          let groupToSelect = this.groups[0];
          const groupFromQuery = this.$location.search()['group'];
          if (groupFromQuery) {
            const includes = this.groups.includes(groupFromQuery);
            if (includes) {
              // Group could be found
              groupToSelect = groupFromQuery;
            } else {
              // Group couldnt be found
              this.grid = undefined;
            }
          }
  
          this.select(groupToSelect);
        } else {
          this.grid = undefined;
        }
  
        this.$scope.$applyAsync();
      }).finally(() => {
        this.isLoadingGroups = false;
        resolve(true);
      });
    });
  }

  incomingStatus(status: StatusEntryResponse) {
    if (!this.isVehicleDisplayed(status)) {
      return;
    }

    var needToCheckForSpeakingRequest = false;
    switch (status.status) {
      case EVehicleStatus.STATUS_0:
      case EVehicleStatus.STATUS_5:
        needToCheckForSpeakingRequest = true;
        break;
      default:
        needToCheckForSpeakingRequest = this.vehiclesWithSpeakingRequest.length !== 0;
        break;
    }


    // Play or don't play sound
    if (this.isSoundActive) {
      this.$log.info('Play sound on incoming status: ' + status.status);
      switch (status.status) {
        case EVehicleStatus.STATUS_5:
          this.soundStatus5.play();
          break;
        case EVehicleStatus.STATUS_0:
          this.soundStatus0.play();
          break;
      }
    }

    if (needToCheckForSpeakingRequest) {
      this.findVehiclesWithSpeakingRequest(status);
      this.$scope.$applyAsync();
    }
    
  }

  /**
   * Load the whole grid for a given group
   * @param group 
   */
  loadGrid(group: string) {
    this.isLoadingGrid = true;

    this.vehicleService.getGridForGroup(group).then(grid => {
      this.activeGroup = group;
      this.grid = grid;
      this.$location.search('group', group);

      // Create list of all displayed vehicles
      this.lstOfAllDisplayedVehicleIds = [];
      this.grid.rows.forEach(row => {
        row.columns.forEach(column => {
          column.values.forEach(vehicle => {
            this.lstOfAllDisplayedVehicleIds.push(vehicle.id);
          });
        });
      });

      this.findVehiclesWithSpeakingRequest(undefined);
    }).catch(() => {
      this.isLoadingGrid = false;
      this.grid = undefined;
    }).finally(() => {
      this.isLoadingGrid = false;
      this.$scope.$applyAsync();
    });
  }

  select(group: string) {
    this.activeGroup = group;
    this.loadGrid(group);
  }

  isActive(group: string) {
    return group === this.activeGroup;
  }


  /**
   * Returns true if the given vehicle is displayed on the grid
   * @param status 
   */
  isVehicleDisplayed(status: StatusEntryResponse) {
    return this.lstOfAllDisplayedVehicleIds.includes(status.vehicleId);
  }

  /**
   * Find all vehicles with a speaking request
   */
  findVehiclesWithSpeakingRequest(status: StatusEntryResponse) {
    this.vehiclesWithSpeakingRequest = [];
    this.grid.rows.forEach(row => {
      row.columns.forEach(column => {
        column.values.forEach(vehicle => {
          var statusToCompare = vehicle.status;
          if (status && vehicle.id === status.vehicleId) {
            statusToCompare = status.status;
          }
          switch (statusToCompare) {
            case EVehicleStatus.STATUS_0:
            case EVehicleStatus.STATUS_5:
              this.vehiclesWithSpeakingRequest.push(vehicle);
              break;
          }
        });
      });
    });
    // Sort
    this.vehiclesWithSpeakingRequest.sort((v1, v2) => {
      if (v1.status === EVehicleStatus.STATUS_0 && v2.status !== EVehicleStatus.STATUS_0) {
        // STATUS_0 has higher prio than status 5
        return -1;
      }
      if (v2.status === EVehicleStatus.STATUS_0 && v1.status !== EVehicleStatus.STATUS_0) {
        // STATUS_0 has higher prio than status 5
        return 1;
      }

      return v1.statusChangedTimestamp - v2.statusChangedTimestamp;
    });

    if (this.vehiclesWithSpeakingRequest.length === 0) {
      if (this.soundStatus0) {
        this.soundStatus0.pause();
        this.soundStatus0.currentTime = 0;
      }
      if (this.soundStatus5) {
        this.soundStatus5.pause();
        this.soundStatus5.currentTime = 0;
      }
    }
  }
}