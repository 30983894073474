'use strict';

import { ILocationService, ILogService, IRootScopeService, IScope } from "angular";
import { UserAccount } from "../../../../data/account.data";
import { AlarmData } from "../../../../data/alarm.data";
import { AvailabilitySummaryResponse } from "../../../../data/availability.data";
import { UpcomingEvents } from "../../../../data/event.data";
import { RolePrivilege } from "../../../../data/privileges.enum";
import { EWidgetState, WidgetData } from "../../../../data/widget.data";
import CalendarService from "../../../../services/calendar.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./user.dashboard.component.css');

export default class UserDashboardComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {}
    this.template = require('./user.dashboard.component.html');

    this.controller = UserDashboardComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class UserDashboardComponentController {
  private $log: ILogService;
  private $rootScope: IRootScopeService;
  private $scope: IScope;
  private restService: RestService;
  private adminService;
  private calendarService: CalendarService;
  private listeners = [];

  private account: UserAccount;
  public isLoadingAvailability = false;
  public hasAvailability = false;
  public result: AvailabilitySummaryResponse;

  private $location: ILocationService;

  private alarmWidgetData: WidgetData;
  private eventsWidgetData: WidgetData;

  constructor($log: ILogService, $rootScope: IRootScopeService, $scope: IScope, $location: ILocationService, dataService, restService: RestService, calendarService: CalendarService, adminService,
    public privilegeService: PrivilegeService) {
    this.$log = $log;
    this.$scope = $scope;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.adminService = adminService;
    this.restService = restService;
    this.calendarService = calendarService;
    this.account = dataService.getAccount();

    if (this.account) {
      this.initWidgets();
      this.reload();

      if (this.privilegeService.has(RolePrivilege.Availability) &&
        this.account.hasOnlineService) {
        this.hasAvailability = true;
        this.loadAvailability();
      }


      // Listen for new alarms
      this.listeners.push(this.$rootScope.$on('new.alarm', (event, alarmData: AlarmData) => {
        if (this.alarmWidgetData) {
          this.alarmWidgetData.count++;
          if (alarmData.error) {
            this.alarmWidgetData.countError++;
          }
        }
      }));


      this.listeners.push(this.$scope.$on('change.to.calendar', () => {
        this.$location.path('/main/calendar');
      }));


      // Unregister
      this.$scope.$on('$destroy', () => {
        //Each listener has a unregister function. They are stored in listeners array
        this.listeners.forEach((listener) => {
          listener();
        });
      });
    }
  }

  /**
   * Init the widgets
   */
  initWidgets() {
    // Todays alarms
    this.alarmWidgetData = {
      count: 0,
      countError: 0,
      state: EWidgetState.READY
    } as WidgetData;

    // Upcoming Events
    this.eventsWidgetData = {
      count: 0,
      countError: 0,
      state: EWidgetState.READY
    } as WidgetData;

  };

  /**
   * Update all widgets
   */
  updateWidgets() {
    this.loadNumberOfAlarms();
    this.loadNumberOfUpcomingEvents();
  }


  /**
   * Load number of todays alarms
   */
  loadNumberOfAlarms() {

    if (!this.privilegeService.has(RolePrivilege.Home_Alarms_List)) {
      return;
    }

    this.alarmWidgetData.state = EWidgetState.LOADING;
    this.adminService.getNumberOfAlarms((data: WidgetData) => {
      this.alarmWidgetData.count = data.count;
      this.alarmWidgetData.countError = data.countError;
      if (data.countError > 0) {
        this.alarmWidgetData.state = EWidgetState.DANGER;
      } else {
        this.alarmWidgetData.state = EWidgetState.READY;
      }
      this.$scope.$applyAsync();
    }, error => {
      this.$log.error('Could not load number of alarms for user dashboard', error);
    });
  }

  /**
   * Load number of upcoming events
   */
  loadNumberOfUpcomingEvents() {
    if (!this.privilegeService.has(RolePrivilege.EventPlanning_Appointment) && !this.privilegeService.has(RolePrivilege.Home_Dashboard_Calendar_read)) {
      return;
    }
    this.eventsWidgetData.state = EWidgetState.LOADING;
    this.calendarService.getNumberOfUpcomingEvents().then((data: UpcomingEvents) => {
      this.eventsWidgetData.count = data.count;
      if (data.count > 0) {
        this.eventsWidgetData.state = EWidgetState.SUCCESS;
      } else {
        this.eventsWidgetData.state = EWidgetState.READY;
      }
      this.$scope.$applyAsync();
    }).catch(error => {
      this.$log.error('Could not load number of upcoming events', error);
    });

  }

  reload() {
    this.updateWidgets();
  }

  openCalendarView() {
    this.$scope.$emit('change.to.calendar');
  }

  loadAvailability() {
    this.isLoadingAvailability = true;
    this.restService.getAvailabilitySummary().then(result => {
      this.result = result;
    }).finally(() => {
      this.isLoadingAvailability = false;
      this.$scope.$applyAsync();
    })
  }
}
