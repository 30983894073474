'use strict';

require('./checkbox.base.field.css');

export default class CheckboxBaseFieldComponent {
  constructor() {
    this.restrict = 'E';
    this.template = require('./checkbox.base.field.html');
    this.scope = {
      field: '=',
      isDerivedFromTemplate: '='
    };
    this.controller = CheckboxBaseFieldController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}


/* @ngInject */
class CheckboxBaseFieldController {
  constructor() {}
}
