'use strict';

import { ILogService, IRootScopeService, IScope } from "angular";
import { APagerResponseEntry, APagerResponseStatus } from "../../../../data/alarm.data";
import HelperService from "../../../../services/helper.service";

require('./apager.summary.tablerow.component.css');


export default class ApagerSummaryTablerowComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./apager.summary.tablerow.component.html');
    this.scope = {
      apager: '='
    };
    this.controller = ApagerSummaryTablerowComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

/* @ngInject */
class ApagerSummaryTablerowComponentController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public data: Timestamps;
  public isSent = true;
  public apager: APagerResponseEntry;
  public hasApagerToken = false;

  constructor(
    $scope: IScope,
    $rootScope: IRootScopeService,
    $log: ILogService,
    helperService: HelperService) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.data = {
      deltaReceived: '',
      deltaSent: ''
    };

    this.$scope.$watch('ctrl.apager', (oldValue, newValue: APagerResponseEntry) => {
      if (newValue) {
        let deltaReceived = Math.round(this.apager.result.internalProcessingTimeDelta / 1000 * 10) / 10;
        if (deltaReceived > 60) {
          // Hours
          deltaReceived = Math.round(deltaReceived / 60 * 10) / 10;
          if (deltaReceived > 60) {
            deltaReceived = Math.round(deltaReceived / 60 * 10) / 10;
            this.data.deltaReceived = deltaReceived + ' Stunden';
          } else {
            this.data.deltaReceived = deltaReceived + ' Minuten';
          }
        } else {
          this.data.deltaReceived = deltaReceived + ' Sekunden';
        }

        switch (this.apager.result.apagerResponseStatus) {
          case APagerResponseStatus.NOT_SUPPORTED:
          case APagerResponseStatus.NOT_FOUND:
          case APagerResponseStatus.DISABLED:
          case APagerResponseStatus.NOT_AUTHENTICATED:
          case APagerResponseStatus.NO_VALID_LICENCE:
            this.isSent = false;
            break;
        }

        if (helperService.isApagerProToken(this.apager.result.email)) {
          this.apager.result.email = this.apager.result.email.toUpperCase();
          this.hasApagerToken = true;
        }

      }
    });
  }
}

interface Timestamps {
  deltaReceived: string,
  deltaSent: string;
}
