'use strict';

/* @ngInject */
export default class IsDirtyInstanceController {
  constructor($scope, $uibModalInstance, okFunction) {
    this.$scope = $scope;
    this.$uibModalInstance = $uibModalInstance;
    this.okFunction = okFunction;
  }

  cancel() {
    this.$uibModalInstance.close();
  }
  ok() {
    this.$uibModalInstance.close();
    this.okFunction();
  }
}